import restaurant from "./images/president.jpg";
import img_card from "./images/7n.jpg";
import imgheaderrestaurant from "./images/4n.jpg";
import psrestaurant from "./images/14.jpg";
import imgheader from "./images/211.jpg";
import imgheaderm from "./images/212.jpg";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/img4.jpg";
import imgheadermiinimb from "./images/img4.jpg";
import icon from "./images/porumbel.png";


const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Alexandru",
        mireasa: "Marina",
        data: "15 Iunie 2024",
        data_confirmare: "20 mai 2024",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "sashab686@gmail.com", 
       tel: "+37379835614",
       phone: "tel:+37379835614",
       viber: "viber://chat?number=%2B37379835614",
       whatsapp: "https://wa.me/+37379835614",
       messenger: "https://www.messenger.com/t/100002166075090",
       tel1: "+37360308216",
       phone1: "tel:+37360308216",
       viber1: "viber://chat?number=%2B37360308216",
       whatsapp1: "https://wa.me/+37360308216",
       messenger1: "https://www.messenger.com/t/100002166075090",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "President",
            name1: "",
            data: "15 iunie 2024, sâmbătă, ora 17:00",
            adress: "bd. Dacia 58/5, Chișinău, R. Moldova",
            harta: "https://maps.app.goo.gl/JwdTpnXD8hmQsykh9",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10891.711762948315!2d28.8910085!3d46.9631058!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c979ca633598ed%3A0x914c0501652568ff!2sTerasa%20Restaurant%20PRESIDENT!5e0!3m2!1sro!2s!4v1709037103695!5m2!1sro!2s" 
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care vom celebra căsătoria nostră, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            img2: icon,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            message1: "",
            parintii_nume1: "",
            parintii_nume2: "",
            parintii_nume3: "",
            parintii_nume4: "",
            nasii: "NAȘII",
            nasii_nume: "Mihail & Eugenia",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Dragostea constă în dorința de a da ceea ce este al tău altuia și de a simți fericirea acestuia ca și cum ar fi a ta. \" – Emanuel Swdenborg",
        }
    ],
    blogRestaurant: [
        {

            img: psrestaurant,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
          
        }
    ],
}

export default data;